<template>
  <div>
    <div class="filter">
      <div class="left" @click="changePlatform">
        <p class="tit">所属平台</p>
        <p class="select">
          {{ checkMerchant.merchantName }}<van-icon name="play" />
        </p>
      </div>
      <!-- <div class="right" @click="changeChannel">
        <p class="tit">所属频道</p>
        <p class="select">全部<van-icon name="play" /></p>
      </div> -->
    </div>
    <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
      <van-list
        v-model="isUpLoading"
        :finished="upFinished"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        :finished-text="list && list.length != 0?'暂无数据':''"
        @load="onLoad"
      >
        <div class="product-list" v-if="list && list.length != 0">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="detail(item)"
          >
            <div class="img">
              <img :src="item.imgUrl" alt="" />
              <div v-if="item.commission">
                <p>预计返{{ item.commission }}元</p>
              </div>
            </div>
            <div class="msg">
              <p class="name">{{ item.title }}</p>
              <p class="price">{{ item.subTitle }}</p>
              <div class="handle">
                <div class="channel">
                  <p>{{ item.merchantName }}丨{{ item.updateTime }}</p>
                </div>
                <div class="num">
                  <van-icon size="16px" color="#999" name="eye-o" />
                  <p>{{ item.displayHits }}</p>
                </div>
                <div class="remove" @click.stop="remove(item)">
                  <img src="../../../assets/del-icon.png" alt="" />
                  <p>删除</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="nay" v-if="list && list.length == 0">
      <img src="../../../assets/empty.png" alt="" />
      <p>暂无数据</p>
    </div>
    <picker ref="refPicker" @merchant="getMerchant"></picker>
  </div>
</template>

<script>
import picker from "../../../component/picker";
import {
  getFavoriteList,
  merchantList,
  deleteFavorite,
} from "../../../utils/api";
export default {
  props: ["shopId", "shopMsg"],
  data() {
    return {
      contentTypeList: ["yuge", "shop"],
      list: null,
      checkMerchant: {
        merchantName: "全部",
        merchantId: null,
      },
      merchantData: null,
      isUpLoading: false,
      upFinished: false,
      isDownLoading: false,
      pageNo: 1,
      pageSize: 15,
      keyword:""
    };
  },
  components: {
    picker,
  },
  watch: {},
  created() {},
  async mounted() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    await this.merchantList();
    await this.getFavoriteList();
    this.$toast.clear();
  },
  methods: {
    onDownRefresh() {
      this.pageNo = 1;
      this.isUpLoading = true;
      this.upFinished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.getFavoriteList(); // 加载数据
    },
    onLoad() {
      setTimeout(() => {
        this.pageNo++;
        this.getFavoriteList();
      }, 1000);
    },
    //跳转文章详情
    detail(item) {
      this.$router.push({
        path: `/${this.shopMsg.shopAlias}/detail`,
        query: {
          contentId: item.contentId,
        },
      });
    },
    //删除收藏
    remove(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "您确认取消收藏该商品？",
        })
        .then(() => {
          // on confirm
          let params = {
            shopId: this.shopId,
            favoriteId: item.id,
            contentId: item.contentId,
            contentType: item.contentType,
          };
          deleteFavorite(params).then((res) => {
            console.log(res);
            this.$toast.success("已取消收藏");
            this.getFavoriteList();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //回传平台信息
    getMerchant(data) {
      console.log(data);
      this.merchantData.map((item) => {
        if (item.merchantName == data) {
          this.checkMerchant = item;
          this.isUpLoading = false;
          this.upFinished = false;
          this.pageNo = 1;
          this.getFavoriteList();
        }
      });
    },
    changePlatform() {
      this.$refs.refPicker.showPicker = true;
    },
    //平台列表
    async merchantList() {
      let params = {
        shopId: this.shopId,
        isPlay:false
      };
      await merchantList(params).then((res) => {
        this.$refs.refPicker.columns = [];
        let obj = {
          merchantName: "全部",
          merchantId: null,
        };
        res.data.unshift(obj);
        this.merchantData = res.data;
        res.data.map((item) => {
          this.$refs.refPicker.columns.push(item.merchantName);
        });
      });
    },
    //收藏-商城列表
    async getFavoriteList() {
      let params = {
        shopId: this.shopId,
        contentTypeList: this.contentTypeList,
        merchantId: this.checkMerchant.merchantId,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      if(this.keyword){
        params.keyword = this.keyword
      }

      await getFavoriteList(params).then((res) => {
        console.log(res);
        // this.list = res.data;
        if (this.pageNo === 1) {
            this.list = res.data;
          } else {
            res.data.map((item) => {
              this.list.push(item);
            });
          }

          if (res.data == null || res.data.length === 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (res.data.length < this.pageSize) {
            // 最后一页不足5条的情况
            this.upFinished = true;
          }

          this.isUpLoading = false;
      }).finally(() => {
          this.isDownLoading = false;
          this.isUpLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
$primary-color: #ff804d;
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .left {
    display: flex;
    align-items: center;
    .tit {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 15px;
    }
    .select {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 12px;
      display: flex;
      align-items: center;
      /deep/ .van-icon {
        transform: rotate(90deg);
        margin-top: -2px;
        margin-left: 3px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    .tit {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 15px;
    }
    .select {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 12px;
      display: flex;
      align-items: center;
      /deep/ .van-icon {
        transform: rotate(90deg);
        margin-top: -2px;
        margin-left: 3px;
      }
    }
  }
}
.nay {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  img {
    width: 215px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 20px;
  }
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
  .img {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    position: relative;
    img {
      width: 100%;
    }
    div {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 18px;
      background: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      white-space: nowrap;
      color: #fff;
      font-size: 20px;
      line-height: 10px;
      transform: scale(0.5);
    }
  }
  .msg {
    width: 100%;
    flex: 1;
    .name {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 17px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .price {
      font-size: 12px;
      font-weight: bold;
      color: #e62828;
      line-height: 12px;
      margin: 10px 0 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    .handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
      .channel {
        display: flex;
        align-items: center;
      }
      .num {
        display: flex;
        align-items: center;
        p {
          margin-left: 5px;
        }
      }
      .remove {
        display: flex;
        align-items: center;
        img {
          width: 11px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
