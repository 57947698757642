<template>
  <div>
    <div class="filter">
      <div class="left" @click="changePlatform">
        <p class="tit">所属平台</p>
        <p class="select">
          {{ checkMerchant.merchantName }}<van-icon name="play" />
        </p>
      </div>
      <!-- <div class="right" @click="changeChannel">
        <p class="tit">所属频道</p>
        <p class="select">全部<van-icon name="play" /></p>
      </div> -->
    </div>
    <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
      <van-list
        v-model="isUpLoading"
        :finished="upFinished"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        :finished-text="list && list.length != 0?'暂无数据':''"
        @load="onLoad"
      >
        <div class="play-list" v-if="list && list.length != 0">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="jumpUrl(item)"
          >
            <div class="img">
              <img class="image" :src="item.imgUrl" alt="" />
              <p
                :class="
                  item.lyStatus === 0
                    ? 'status bg1'
                    : item.lyStatus === 1
                    ? 'status bg2'
                    : 'status bg2'
                "
              >
                {{
                  item.lyStatus === 0
                    ? "抢购中"
                    : item.lyStatus === 1
                    ? "已售完"
                    : "已下架"
                }}
              </p>
              <div class="remove" @click.stop="remove(item)">
                <img src="../../../assets/del-icon2.png" alt="" />
                <p>删除</p>
              </div>
            </div>
            <p
              :class="
                item.lyStatus === 0
                  ? 'name status1'
                  : item.lyStatus === 1
                  ? 'name status2'
                  : 'name status2'
              "
            >
              {{ item.title }}
            </p>
            <p
              :class="
                item.lyStatus === 0
                  ? 'tip status1'
                  : item.lyStatus === 1
                  ? 'tip status2'
                  : 'tip status2'
              "
            >
              {{ item.detail }}
            </p>
            <div class="handle">
              <div class="left">
                <p class="price">¥ {{ item.price / 100 }}</p>
                <p class="market-price">{{ item.subTitle }}</p>
              </div>
              <div class="right">
                <p class="rebate" v-if="item.commission">
                  返¥{{ item.commission / 100 }}
                </p>
                <p class="channel">{{ item.merchantName }}</p>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="nay" v-if="list && list.length == 0">
      <img src="../../../assets/empty.png" alt="" />
      <p>暂无数据</p>
    </div>
    <picker ref="refPicker" @merchant="getMerchant"></picker>
  </div>
</template>

<script>
import picker from "../../../component/picker";
import {
  getFavoriteList,
  merchantList,
  deleteFavorite,
} from "../../../utils/api";
export default {
  props: ["shopId"],
  data() {
    return {
      contentTypeList: ["play"],
      list: null,
      checkMerchant: {
        merchantName: "全部",
        merchantId: null,
      },
      merchantData: null,
      isUpLoading: false,
      upFinished: false,
      isDownLoading: false,
      pageNo: 1,
      pageSize: 15,
      keyword:""
    };
  },
  components: {
    picker,
  },
  watch: {},
  async mounted() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    await this.merchantList();
    await this.getFavoriteList();
    this.$toast.clear();
  },
  methods: {
    onDownRefresh() {
      this.pageNo = 1;
      this.isUpLoading = true;
      this.upFinished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.getFavoriteList(); // 加载数据
    },
    onLoad() {
      setTimeout(() => {
        this.pageNo++;
        this.getFavoriteList();
      }, 1000);
    },
    //跳转
    jumpUrl(item) {
      window.location.href = item.lyCpsUrl;
    },
    //删除收藏
    remove(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "您确认取消收藏该商品？",
        })
        .then(() => {
          // on confirm
          let params = {
            shopId: this.shopId,
            favoriteId: item.id,
            contentId: item.contentId,
            contentType: item.contentType,
          };
          deleteFavorite(params).then((res) => {
            console.log(res);
            this.$toast.success("已取消收藏");
            this.getFavoriteList();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //回传平台信息
    getMerchant(data) {
      this.merchantData.map((item) => {
        if (item.merchantName == data) {
          this.checkMerchant = item;
          this.isUpLoading = false;
          this.upFinished = false;
          this.pageNo = 1;
          this.getFavoriteList();
        }
      });
    },
    changePlatform() {
      this.$refs.refPicker.showPicker = true;
    },
    //平台列表
    async merchantList() {
      let params = {
        shopId: this.shopId,
        isPlay:true
      };
      await merchantList(params).then((res) => {
        this.$refs.refPicker.columns = [];
        let obj = {
          merchantName: "全部",
          merchantId: null,
        };
        res.data.unshift(obj);
        this.merchantData = res.data;
        res.data.map((item) => {
          this.$refs.refPicker.columns.push(item.merchantName);
        });
      });
    },
    //收藏-玩耍列表
    async getFavoriteList() {
      let params = {
        shopId: this.shopId,
        contentTypeList: this.contentTypeList,
        merchantId: this.checkMerchant.merchantId,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      if(this.keyword){
        params.keyword = this.keyword
      }
      await getFavoriteList(params).then((res) => {
        // this.list = res.data;
        if (this.pageNo === 1) {
            this.list = res.data;
          } else {
            res.data.map((item) => {
              this.list.push(item);
            });
          }
          if (res.data == null || res.data.length === 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (res.data.length < this.pageSize) {
            // 最后一页不足5条的情况
            this.upFinished = true;
          }

          this.isUpLoading = false;
      }).finally(() => {
          this.isDownLoading = false;
          this.isUpLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
$primary-color: #ff804d;
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .left {
    display: flex;
    align-items: center;
    .tit {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 15px;
    }
    .select {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 12px;
      display: flex;
      align-items: center;
      /deep/ .van-icon {
        transform: rotate(90deg);
        margin-top: -2px;
        margin-left: 3px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    .tit {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 15px;
    }
    .select {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 12px;
      display: flex;
      align-items: center;
      /deep/ .van-icon {
        transform: rotate(90deg);
        margin-top: -2px;
        margin-left: 3px;
      }
    }
  }
}
.nay {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  img {
    width: 215px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 20px;
  }
}
.play-list {
  margin: 10px 0;
}
.item {
  margin-top: 10px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 15px;
  .img {
    width: 100%;
    position: relative;
    .image {
      width: 100%;
      border-radius: 4px;
    }
    .status {
      position: absolute;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      // background: #999999;
      left: 10px;
      top: 10px;
      z-index: 99;
      border-radius: 17px;
    }
    .bg2 {
      background: #999999;
    }
    .bg1 {
      background: $primary-color;
    }
    .remove {
      position: absolute;
      left: 10;
      top: 10;
      z-index: 99;
      bottom: 10px;
      left: 10px;
      display: flex;
      align-items: center;
      img {
        width: 10px;
        margin-right: 5px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 12px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .status1 {
    color: #333;
  }
  .status2 {
    color: #999;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    // color: #333333;
    line-height: 20px;
    margin-top: 10px;
  }
  .tip {
    font-size: 12px;
    font-weight: 400;
    // color: #333333;
    line-height: 17px;
    margin: 10px 0 17px;
  }
  .handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .price {
        font-size: 16px;
        font-weight: bold;
        color: $primary-color;
        line-height: 22px;
      }
      .market-price {
        font-size: 12px;
        text-decoration: line-through;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-left: 5px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .rebate {
        padding: 0 10px;
        background: $primary-color;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        border-radius: 17px;
      }
      .channel {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        margin-left: 10px;
      }
    }
  }
}
</style>
