<template>
  <div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      showPicker: false,
      columns: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
      this.$emit('merchant',value)
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;

</style>