var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"left",on:{"click":_vm.changePlatform}},[_c('p',{staticClass:"tit"},[_vm._v("所属平台")]),_c('p',{staticClass:"select"},[_vm._v(" "+_vm._s(_vm.checkMerchant.merchantName)),_c('van-icon',{attrs:{"name":"play"}})],1)])]),_c('van-pull-refresh',{on:{"refresh":_vm.onDownRefresh},model:{value:(_vm.isDownLoading),callback:function ($$v) {_vm.isDownLoading=$$v},expression:"isDownLoading"}},[_c('van-list',{attrs:{"finished":_vm.upFinished,"error-text":"请求失败，点击重新加载","immediate-check":false,"finished-text":_vm.list && _vm.list.length != 0?'暂无数据':''},on:{"load":_vm.onLoad},model:{value:(_vm.isUpLoading),callback:function ($$v) {_vm.isUpLoading=$$v},expression:"isUpLoading"}},[(_vm.list && _vm.list.length != 0)?_c('div',{staticClass:"play-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.jumpUrl(item)}}},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"image",attrs:{"src":item.imgUrl,"alt":""}}),_c('p',{class:item.lyStatus === 0
                  ? 'status bg1'
                  : item.lyStatus === 1
                  ? 'status bg2'
                  : 'status bg2'},[_vm._v(" "+_vm._s(item.lyStatus === 0 ? "抢购中" : item.lyStatus === 1 ? "已售完" : "已下架")+" ")]),_c('div',{staticClass:"remove",on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_c('img',{attrs:{"src":require("../../../assets/del-icon2.png"),"alt":""}}),_c('p',[_vm._v("删除")])])]),_c('p',{class:item.lyStatus === 0
                ? 'name status1'
                : item.lyStatus === 1
                ? 'name status2'
                : 'name status2'},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{class:item.lyStatus === 0
                ? 'tip status1'
                : item.lyStatus === 1
                ? 'tip status2'
                : 'tip status2'},[_vm._v(" "+_vm._s(item.detail)+" ")]),_c('div',{staticClass:"handle"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"price"},[_vm._v("¥ "+_vm._s(item.price / 100))]),_c('p',{staticClass:"market-price"},[_vm._v(_vm._s(item.subTitle))])]),_c('div',{staticClass:"right"},[(item.commission)?_c('p',{staticClass:"rebate"},[_vm._v(" 返¥"+_vm._s(item.commission / 100)+" ")]):_vm._e(),_c('p',{staticClass:"channel"},[_vm._v(_vm._s(item.merchantName))])])])])}),0):_vm._e()])],1),(_vm.list && _vm.list.length == 0)?_c('div',{staticClass:"nay"},[_c('img',{attrs:{"src":require("../../../assets/empty.png"),"alt":""}}),_c('p',[_vm._v("暂无数据")])]):_vm._e(),_c('picker',{ref:"refPicker",on:{"merchant":_vm.getMerchant}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }