<template>
  <div class="myCollection">
    <div class="tab">
      <p
        :class="active === index ? 'tab-active' : 'tab-no-active'"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(index)"
      >
        {{ item }}
      </p>
    </div>
    <div class="search">
      <van-search
        v-model="keyword"
        shape="round"
        :placeholder="active == 0?'搜索商品名/品牌':'搜索景点/商铺名'"
        @search="onSearch"
      />
    </div>
    <productList
      ref="refProduct"
      :shopId="shopId"
      :shopMsg="shopMsg"
      v-if="active === 0 && shopId"
    ></productList>
    <playList
      ref="refPlay"
      :shopId="shopId"
      v-if="active === 1 && shopId"
    ></playList>
    
  </div>
</template>

<script>
import { getShopName } from "../../utils/api";
import playList from "././component/playList";
import productList from "././component/productList";
export default {
  components: {
    playList,
    productList,
  },
  data() {
    return {
      shopId: "",
      tabList: ["商城", "玩耍"],
      active: 0,
      shopMsg: {},
      keyword: "", //搜索
      
    };
  },
  async created() {
    await this.getshopName();
  },

  methods: {
    //搜索
    onSearch() {
      if(this.active == 0){   
        this.$refs.refProduct.keyword = this.keyword;
        this.$refs.refProduct.isUpLoading = false;
        this.$refs.refProduct.upFinished = false;
        this.$refs.refProduct.pageNo = 1;
        this.$refs.refProduct.getFavoriteList();
      }else{
        this.$refs.refPlay.keyword = this.keyword;
        this.$refs.refPlay.isUpLoading = false;
        this.$refs.refPlay.upFinished = false;
        this.$refs.refPlay.pageNo = 1;
        this.$refs.refPlay.getFavoriteList();
      }
    },
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        this.shopMsg = res;
        this.shopId = res.id;
        this.$toast.clear();
      });
    },
    //切换tab
    changeTab(index) {
      this.keyword = "";
      this.active = index;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
.myCollection {
  padding: 12px 15px;
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .left {
      display: flex;
      align-items: center;
      .tit {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 15px;
      }
      .select {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 12px;
        display: flex;
        align-items: center;
        /deep/ .van-icon {
          transform: rotate(90deg);
          margin-top: -2px;
          margin-left: 3px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .tit {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 15px;
      }
      .select {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 12px;
        display: flex;
        align-items: center;
        /deep/ .van-icon {
          transform: rotate(90deg);
          margin-top: -2px;
          margin-left: 3px;
        }
      }
    }
  }
  .tab {
    display: flex;
    align-items: center;
    p {
      padding-bottom: 5px;
      margin-right: 20px;
    }
  }
  .tab-active {
    font-size: 16px;
    line-height: 16px;
    color: $primary-color;
    font-weight: bold;
    border-bottom: 4px solid $primary-color;
  }
  .tab-no-active {
    font-size: 14px;
    line-height: 14px;
    color: #333;
    font-weight: 400;
  }
  .search {
    margin-top: 15px;
    // padding: 0 15px;
  }
  /deep/ .van-field__left-icon .van-icon,
  .van-field__right-icon .van-icon {
    color: #999;
  }
  /deep/ .van-search {
    padding: 5px 10px;
  }
  /deep/ .van-search__content--round {
    border-radius: 4px;
  }
  /deep/ .van-search .van-cell {
    padding: 2px;
  }
  /deep/ .van-search .van-cell {
    padding: 2px;
  }
  /deep/ .van-search {
    padding: 0;
    border-radius: 4px !important;
  }
  /deep/ .van-search__content {
    height: 32px;
    background: #fcfcfc;
    border-radius: 16px;
    border: 1px solid #eeeeee;
  }
}
</style>